import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import houseHackingImg from '../../assets/house-hacking-2x2.png'
import Sidebar from '../../components/Sidebar'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ElectricCarSavingsCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const GraphDesktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
`

const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  align-items: start;
  padding: 0 16px;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.black};
  }
`;

const InputGroup = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: start;
  /* display: flex; */
  flex-wrap: wrap;
  /* padding: 8px 8px 0 8px; */
  /* border-radius: 8px; */
  /* padding: 12px 12px 0px 12px; */
  /* border: 1px solid ${(props) => props.theme.theme.border.tertiary}; */
  grid-template-columns: 1fr;
  /* grid-gap: 24px; */
  margin: 0 0 0px 0;

  /* h3 {
    color: ${(props) => props.theme.theme.text.primary};
    text-transform: uppercase;
    margin: 0 0 12px 0;
    font-weight: 500;
  } */

  @media (max-width: 500px) {
    /* padding: 24px 0px 0 0px; */
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const SliderContainer = styled.div`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  border-radius: 8px;
  padding: 8px;
`

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const FinancesTableWrapper = styled.div`
  overflow-x: scroll;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};

  @media (max-width: 500px) {
    max-width: 90vw;
  }
`;
const FinancesTableGrid = styled.div`
  display: grid;
  margin: 0 auto;
`;

const FinancesTableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  justify-items: center;
  padding: 4px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(5, 85px);
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ;
  grid-gap: 24px;
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const Image = styled.img`
  width: 100%;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function NumberFormatCustomTwoDecimal(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function NumberFormatGasPrice(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix=" per gallon"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function NumberFormatElectricityRate(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix=" per kWh"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function NumberFormatBasic(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatBatteryCapacity(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix=" kWh"
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatRange(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix=" miles"
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatMPG(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix=" miles per gallon"
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatAnnualMileage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix=" miles / year"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ElectricCarSavingsCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ annualMileage, setAnnualMileage ] = useState(13500)
  const [ localFuelPrice, setLocalFuelPrice ] = useState(3.00)
  const [ localElectricityRate, setLocalElectricityRate ] = useState(0.13)
  const [ electricCarRange, setElectricCarRange ] = useState(300)
  const [ gasCarMPG, setGasCarMPG ] = useState(30)
  const [ electricCarCapacity, setElectricCarCapacity ] = useState(75)
  const [ electricCarPurchasePrice, setElectricCarPurchasePrice ] = useState(38000)
  const [ gasCarPurchasePrice, setGasCarPurchasePrice ] = useState(30000)
  const [ freeChargingUtilization, setFreeChargingUtilization ] = useState(0)

  const annualFuelCostEV = annualMileage * ( electricCarCapacity / electricCarRange ) * localElectricityRate * (1-(freeChargingUtilization/100))
  const annualFuelCostGas = (annualMileage / gasCarMPG) * localFuelPrice
  const savingsFromEV = annualFuelCostGas - annualFuelCostEV
  const breakEvenPoint = ( electricCarPurchasePrice - gasCarPurchasePrice ) / savingsFromEV

  return (
    <Layout>
      <SEO
        title={'Electric Car Savings Calculator'}
        description={'Discover how much you can save by switching to an electric vehicle'}
        keywords={site.keywords}
      />
      <CustomReactTooltip />
      <ContainerMain>
        <h1>Electric Car Savings Calculator</h1>
        <H2>Discover how much you can save by switching to an electric vehicle</H2>

      </ContainerMain>

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
          <ColumnsWrapper>
            <InputGroup>
              <GroupLabel>Your details</GroupLabel>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Miles driven per year"
                  value={annualMileage}
                  onChange={event => setAnnualMileage(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatAnnualMileage,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`The average American drives about 13,500 miles annually.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Local gas price"
                  value={localFuelPrice}
                  onChange={event => setLocalFuelPrice(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatGasPrice,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`The average price of fuel in the USA is about $3.00 per gallon`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-required"
                    label="Local electricity rate"
                    value={localElectricityRate}
                    onChange={event => setLocalElectricityRate(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatElectricityRate,
                    }}
                    suffix= " test"
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`The average electricity rate in the USA is about 13 cents per kiloWatt hour (kWh).`}
                  />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <SliderContainer>
                  <Label>
                    How often will you use free charging?
                    <MdHelp size="1.4rem"
                        data-tip={`You may be able to take advantage of free charging at your workplace or using public charging stations. Selecting 100% means that you are using all free charging and never charging at home.`}
                        />
                  </Label>

                  <SliderGrid>
                    <ThemedSlider
                      value={freeChargingUtilization}
                      aria-labelledby="discrete-slider-always"
                      step={1}
                      min={0}
                      max={100}
                      marks={[
                        {
                          value: 0,
                          label: '0%'
                        },
                        {
                          value: 50,
                          label: '50%'
                        },
                        {
                          value: 100,
                          label: '100%'
                        }
                      ]}
                      valueLabelDisplay="auto"
                      onChange={ (event, value) => setFreeChargingUtilization(value)}
                    />
                  </SliderGrid>
                </SliderContainer>
              </FlexInput>
            </InputGroup>

            <InputGroup>
              <GroupLabel>Electric Vehicle</GroupLabel>
              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Battery capacity"
                  value={electricCarCapacity}
                  onChange={event => setElectricCarCapacity(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatBatteryCapacity,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the battery capacity of your EV in kiloWatt hours (kWh) See the table below for battery capacity of common EVs.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Range"
                  value={electricCarRange}
                  onChange={event => setElectricCarRange(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatRange,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the distance that your EV can travel on a full battery charge.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Vehicle purchase price"
                  value={electricCarPurchasePrice}
                  onChange={event => setElectricCarPurchasePrice(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your estimated EV purchase price after taking into account any tax credits that you may qualify for.`}
                />
                </InputItem>
              </FlexInput>
            </InputGroup>

            <InputGroup>
              <GroupLabel>Comparable Gas Vehicle</GroupLabel>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Average gas mileage (MPG)"
                  value={gasCarMPG}
                  onChange={event => setGasCarMPG(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatMPG,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the fuel efficiency in miles per gallon (MPG) for a comparable gas vehicle.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "250px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Vehicle purchase price"
                  value={gasCarPurchasePrice}
                  onChange={event => setGasCarPurchasePrice(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the purchase price for a comparable gas vehicle.`}
                />
                </InputItem>
              </FlexInput>

            </InputGroup>
          </ColumnsWrapper>

          <ColumnsWrapper>
            <AllocationGroupGray>
              <Label>
                Break Even Point
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is how soon your fuel savings from switching to an EV makes up for the difference in purchase price. The lower (and thus faster) this number, the sooner that your EV pays for itself.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={breakEvenPoint.toFixed(1)}
                  thousandSeparator
                  suffix=" years"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Annual Electricity Cost
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your total yearly electricity costs to charge your EV.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={annualFuelCostEV.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" per year"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Annual Gas Cost
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the total you would spend each year on gas for a comparable gas vehicle.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={annualFuelCostGas.toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" per year"
                />
              </span>
            </AllocationGroupGray>

            <AllocationGroupGreen>
              <Label>
                Annual EV Savings
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your annual savings on fuel from driving an EV instead of a gas vehicle.`}
                />
              </Label>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={(savingsFromEV).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                  suffix=" per year"
                />
              </span>
            </AllocationGroupGreen>

          </ColumnsWrapper>

          </form>

            {/* <GraphDesktop>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={50} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphDesktop> */}

            {/* <GraphMobile>
              <ResponsiveContainer width='100%' height={500} >
                <AreaChart data={loanData}
                    margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="left"
                    mirror
                  />
                  <YAxis
                    tickFormatter={tick => {
                      return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    }}
                    yAxisId="right"
                    orientation="right"
                    mirror
                  />
                  <Tooltip
                    content={<CustomTooltip/>}
                  />
                  <Legend verticalAlign="top" height={110} />
                  <Area type='monotone' name="Loan Balance ($)" dataKey='loanBalance' stroke='#3AC732' fill='#CFF0CD' yAxisId="left" />
                  <Area type='monotone' name="Monthly payment ($)" dataKey='monthlyPayment' stroke='#000' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards interest ($)" dataKey='interestPortion' stroke='#FF1733' fillOpacity={0} yAxisId="right"/>
                  <Area type='monotone' name="Payment towards principal ($)" dataKey='principalPortion' stroke='#1274E0' fillOpacity={0} yAxisId="right"/>
                </AreaChart>
              </ResponsiveContainer>
            </GraphMobile> */}
        </CalculatorGrid>

      </CalculatorPanel>

      <ContainerTwoCol>
        <ContainerContent>
          <h2></h2>
          <p>
            Thanks to the likes of Elon Musk and Tesla, in the last few years electric vehicles (EVs) have become more widespread than ever before.
            With impressive safety, advances in battery technology and growing charging infrastructure, EVs are increasingly
            attractive to average drivers. Depending on your personal driving situation, an electric vehicle may pay for itself faster
            than you would think when compared to an equivalent gasoline vehicle.
          </p>

          <h2>Using this calculator</h2>
          <p>This calculator is intended to help you understand how much you could save by going with an electric vehicle the next time you are looking to purchase a new ride.</p>

          <p>Simply adjust the inputs above and then your annual savings and break even point on your purchase will be automatically calculated!</p>

          <ul>
            <li><strong>Miles driven per year</strong> is your average expected driving mileage per year. According to the U.S. Department of Transportation's Federal Highway Administration,
              the average American drives around 13,500 miles every year.
            </li>
            <li>
              <strong>Local gas price</strong> can vary widely by city and state. <a href="https://www.gasbuddy.com" target="_blank">GasBuddy.com</a> is a great resource for finding the best gas price in your area.
              The average gas price in the USA in 2021 is around $3.00 per gallon
            </li>
            <li>
              <strong>Local electricity rate</strong> is the rate in dollars per kiloWatt hour (kWh) that you pay for electricity to charge your electric vehicle.
            </li>
            <li>
              <strong>How often will you use free charging?</strong> Adjust this slider to factor in any opportunities for free EV charging that you would plan to
              take advantage of with your EV. For example, you may be able to charge your EV for free at your workplace or find free public charging stations
              using <a href="https://plugshare.com" target="_blank">PlugShare.com</a>. Selecting 100% means that you are using all free charging and never charging at home.
            </li>
            <li>
              <strong>Battery capacity</strong> is the full charging capacity of your prospective EV in kiloWatt hours (kWh).
            </li>
            <li>
              <strong>Range</strong> is the distance your prospective EV can travel on a full battery charge.
            </li>
            <li>
              <strong>EV purchase price</strong> is the all-in purchase price of your EV after factoring in any tax credits that you may be eligible for.
              Check out <a href="https://www.fueleconomy.gov/feg/taxevb.shtml" target="_blank">FuelEconomy.gov</a> to see what tax credits you may qualify for with your EV purchase.
            </li>
            <li>
              <strong>Average gas mileage</strong> is the fuel efficiency in miles per gallon (MPG) of a comparable gas vehicle.
            </li>
            <li>
              <strong>Gas vehicle purchase price</strong> is the all-in purchase price of a comparable gas vehicle.
            </li>
          </ul>

          <p>Here's how to understand the results:</p>
          <ul>
            <li>
              <strong>Break even point</strong> is how long it takes for the fuel savings from your electric vehicle to make up for the difference in price between
              an electric vehicle and an equivalent gas vehicle. For example, if your break even point is 4 years, then after 4 years you will have "broken even" on your
              EV purchase meaning that your savings on fuel have already paid for the difference in purchase price. The lower your break even point, the better. Since the average driver keeps a
              new car for 6 years, you should aim to have a break even point that is less than 6 years.
            </li>
            <li><strong>Annual electricity cost</strong> is the yearly cost of electricity to charge your electric vehicle.</li>
            <li><strong>Annual gas cost</strong> is the yearly cost of fuel to drive your gasoline vehicle.</li>
            <li>
              <strong>Annual EV savings</strong> is the yearly fuel cost savings that you would experience by driving an electric vehicle rather than a gas vehicle.
              Annual EV savings = annual gas cost - annual electricity cost.
            </li>
          </ul>

          <h2>Advantages of electric vehicles</h2>
          <ul>
            <li>
              <strong>They are flat-out fun to drive.</strong> A unique quality of EVs is that they produce instant peak torque, meaning that you have maximum acceleration and power the moment you step on the gas pedal.
              This translates to a level of responsiveness and smoothness in daily driving that gas cars simply cannot match. Joe Rogan stated it elegantly: "I think a Tesla is the most fun thing you could possibly ever buy."
            </li>
            <li><strong>Lower fuel costs compared to gasoline vehicles.</strong> As you can see in this calculator, EVs can offer impressive savings on fuel, especially if you have access to free charging.</li>
            <li>
            <strong>Lower maintenance costs and less frequent maintenance.</strong> Since they don't have a gas engine, electric vehicles do not require oil changes or any other maintenance/repairs associated
              with traditional gas engines. Because they use regenerative braking, EVs are also typically easier on your brakes, leading to fewer brake replacements.
            </li>
            <li>
              <strong>Carpool (HOV) lane access.</strong> In some states like California, driving an electric vehicle can allow you to drive in a Carpool lane (aka High Occupancy Vehicle / HOV) even when you are driving by yourself.
              Since traffic can often move much faster in the HOV lane during rush hour, this is a coveted benefit that can save you valuable time over the ownership of your vehicle.
            </li>
          </ul>
          <p>

          </p>

          <h2>Downsides of electric vehicles</h2>
          <ul>
            <li>
              <strong>Shorter range than gas vehicles.</strong> In general, EVs can travel a much shorter distance on a full charge than a comparable gas vehicle can travel on a full tank.
              For example, the base model Tesla Model 3 offers up to 263 miles of driving range, while a base model Toyota Corolla can go about 435 miles on a full tank.
            </li>
            <li>
              <strong>Recharging the battery takes time.</strong> Depending on your power source, it can take several hours to charge your EVs battery while filling up your gas tank takes just a few minutes.
              On the fast side, Tesla's V3 Supercharger can fill a Tesla Model 3 from 5% to 90% full in about 37 minutes. However if you are charging with a standard 120V home power outlet, it could take as much as 30 hours to fully
              top up your battery.
            </li>
            <li>
              <strong>Having to plan around charging infrastructure.</strong> While EV charging networks are growing rapidly in recent years, charging infrastructure is still limited in many parts of the country.
              This means driving an electric vehicle can introduce headaches when planning a roadtrip and could limit the routes you can travel. When you consider the point above about the time it takes to recharge the battery, you may find
              that an EV is less than ideal for roadtrips.
            </li>
            <li>
              <strong>Higher upfront price than gasoline cars.</strong> When you start shopping around for EVs, it quickly becomes apparent that they cost a premium compared to gas vehicles. Depending on your annual driving mileage, the break even
              point for switching to an EV may be longer than you plan to keep the car, so it wouldn't make financial sense.
            </li>
          </ul>

        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'electric-car-savings-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default ElectricCarSavingsCalc
